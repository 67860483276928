// extracted by mini-css-extract-plugin
export var about = "fundraiserTemplate-module--about--5fda2";
export var address = "fundraiserTemplate-module--address--9c853";
export var buttonContainer = "fundraiserTemplate-module--button-container--15869";
export var details = "fundraiserTemplate-module--details--f4d3f";
export var eventTime = "fundraiserTemplate-module--event-time--3099a";
export var fundraiserDetails = "fundraiserTemplate-module--fundraiser-details--9884d";
export var fundraiserPage = "fundraiserTemplate-module--fundraiser-page--4658a";
export var map = "fundraiserTemplate-module--map--063b5";
export var nickname = "fundraiserTemplate-module--nickname--818b4";
export var subTitle = "fundraiserTemplate-module--sub-title--91469";
export var title = "fundraiserTemplate-module--title--e15f1";