import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { useState } from 'react'
import LocationsMap from 'src/components/molecules/locationsMap'
import { WithContext, Event } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'

import { IFundraiser } from 'src/services/creatioService'
import * as GlobalStyles from 'src/styles/global.module.scss'
import {
  FundraiserPageQuery,
  PrismicLinkType,
  PrismicStoreLocation,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'

import MinimalHero from 'src/components/organisms/heroes/minimalHero'
import DynamicCta from 'src/components/organisms/ctas/dynamicCta'
import FaqCta, { FaqCtaItem } from 'src/components/organisms/ctas/faqCta'
import { CustomLinkButton } from 'src/utils/customLink'
import { getStoreLocationDisplayAddress } from 'src/utils/storeLocationHelper'
import Toaster from 'src/components/atoms/toaster'

import * as Styles from './fundraiserTemplate.module.scss'

const FundraiserTemplate = ({
  data,
  pageContext,
}: PageProps<FundraiserPageQuery, IFundraiser>) => {
  const { urlPdf, urlBWPdf, eventSlug, eventName } = pageContext

  const globalContent = data.prismicGlobalContent?.data
  const location = data.prismicStoreLocation as PrismicStoreLocation
  const locationAddress = location
    ? getStoreLocationDisplayAddress(location)
    : ''
  const locationCoordinates = location
    ? {
        lat: location.data.coordinates?.latitude ?? 0,
        lng: location.data.coordinates?.longitude ?? 0,
      }
    : {
        lat: 0,
        lng: 0,
      }
  const mapLocations = location ? [location] : []

  const [toasterMessage, setToasterMessage] = useState(
    'Link copied to clipboard'
  )
  const [showToaster, setShowToaster] = useState(false)
  const fundraiserUrl = `https://raisingcanes.com/fundraiser/${eventSlug}`
  const handleCopyLinkButton = () => {
    if (typeof navigator === 'undefined') return

    navigator.clipboard
      .writeText(fundraiserUrl)
      .then(() => {
        setToasterMessage('Link copied to clipboard')
      })
      .catch(() => {
        setToasterMessage('Error, could not copy to clipboard')
      })
      .finally(() => {
        setShowToaster(true)
      })
  }

  const copyLinkButton: CustomLinkButton = {
    url: fundraiserUrl,
    onClick: handleCopyLinkButton,
  }

  const pdfLink: PrismicLinkType = {
    url: urlPdf,
    target: '_blank',
  }
  const pdfFaqItem: FaqCtaItem = {
    link: pdfLink,
    linkText: 'Download PDF',
  }

  const pdfBWLink: PrismicLinkType = {
    url: urlBWPdf,
    target: '_blank',
  }
  const pdfBWFaqItem: FaqCtaItem = {
    link: pdfBWLink,
    linkText: 'Download PDF (black and white)',
  }

  return (
    <div className={Styles.fundraiserPage}>
      <MinimalHero
        content={{
          title: globalContent?.fundraiser_hero_text ?? '',
          image: globalContent?.fundraiser_hero_image?.gatsbyImageData,
          imageAlt: globalContent?.fundraiser_hero_image?.alt ?? '',
          titleSize: 'small',
          titleAsH1: false,
        }}
      />
      <section key="details" className={Styles.fundraiserDetails}>
        <div className={Styles.details}>
          <h1 className={Styles.title}>{eventName}</h1>
          <h4 className={Styles.subTitle}>Event Details</h4>
          <p className={Styles.eventTime}>
            Date: {pageContext.eventDate}
            <br />
            Time: {pageContext.eventStart} - {pageContext.eventEnd}
          </p>
          <p className={Styles.address}>{locationAddress}</p>
          <p className={Styles.about}>
            {globalContent?.fundraiser_details_text}
          </p>
          <div className={Styles.buttonContainer}>
            <a
              href={`https://www.google.com/maps/place/${locationAddress}`}
              target="_blank"
              className={`${GlobalStyles.whiteGhostButtonResponsive}`}
              rel="noreferrer"
            >
              {globalContent?.fundraiser_directions_button_text}
            </a>
          </div>
        </div>
        <div key="content" className={Styles.map}>
          <LocationsMap
            locations={mapLocations}
            selectedLocationIndex={0}
            center={locationCoordinates}
            interactive={false}
            zoom={12}
          />
        </div>
      </section>
      <DynamicCta
        content={{
          headline: globalContent?.fundraiser_cta_header ?? '',
          description:
            (globalContent?.fundraiser_cta_description as PrismicStructuredTextType) ??
            undefined,
          link: copyLinkButton,
          linkText: globalContent?.fundraiser_cta_copy_url_button_text ?? '',
          image: globalContent?.fundraiser_cta_image?.gatsbyImageData,
          imageAlt: globalContent?.fundraiser_cta_image?.alt ?? '',
        }}
      />
      <FaqCta
        content={{
          headline: globalContent?.fundraiser_pdf_download_header ?? '',
          image: globalContent?.fundraiser_pdf_download_image?.gatsbyImageData,
          imageAlt: globalContent?.fundraiser_pdf_download_image?.alt ?? '',
          link: globalContent?.fundraiser_pdf_download_link as PrismicLinkType,
          linkText: globalContent?.fundraiser_pdf_download_link_text ?? '',
          items: [pdfFaqItem, pdfBWFaqItem],
        }}
      />

      {showToaster && (
        <Toaster
          message={toasterMessage}
          onClose={() => {
            setShowToaster(false)
          }}
        />
      )}
    </div>
  )
}

export default FundraiserTemplate

export const Head = ({
  pageContext,
}: HeadProps<FundraiserPageQuery, IFundraiser>) => {
  const title = pageContext.eventName ?? 'Fundraiser'
  const description = `Visit a Raising Cane's and mention the "${pageContext.eventName}" fundraiser at the register and Cane's will donate a portion of the sale to the fundraiser!`
  const url = pageContext.eventSlug
    ? `/fundraiser/${pageContext.eventSlug}`
    : ''

  const schema: WithContext<Event> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    url,
    name: title,
    description,
  }

  return DocumentHead({
    title,
    url,
    description,
    imageUrl: '',
    pageSchema: schema,
  })
}

export const query: any = graphql`
  query FundraiserPage($storeCode: String) {
    prismicStoreLocation(uid: { eq: $storeCode }) {
      data {
        coordinates {
          latitude
          longitude
        }
        external_location_data {
          address1
          address2
          city
          state
          postal_code
        }
        override_external_hours
        override_external_phone
        override_external_address_1
        override_external_address_2
        override_external_city
        override_external_state
        override_external_zip_code
      }
    }
    prismicGlobalContent {
      data {
        fundraiser_hero_text
        fundraiser_hero_image {
          gatsbyImageData
          alt
        }
        fundraiser_details_text
        fundraiser_directions_button_text
        fundraiser_cta_header
        fundraiser_cta_description {
          richText
        }
        fundraiser_cta_copy_url_button_text
        fundraiser_cta_image {
          gatsbyImageData
          alt
        }
        fundraiser_pdf_download_header
        fundraiser_pdf_download_image {
          gatsbyImageData
          alt
        }
        fundraiser_pdf_download_link {
          ...BasicLinkFragment
        }
        fundraiser_pdf_download_link_text
      }
    }
  }
`
